import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { ContainerFixed } from '../components/ui'
import { PostTemplate } from '../components/posts'
import { MetaTagsMustHave, MetaTagsPost, SchemaArticle } from '../components/seo'

const Post = ({ data, location }) => {
  const { post } = data.wp
  const { seo, featuredImage } = post

  return (
    <ContainerFixed
      p={0}
      maxWidth={1000}
    >
      <MetaTagsMustHave
        {...seo}
      />
      <MetaTagsPost
        {...seo}
        author={post.author.node.name}
      />
      <SchemaArticle
        {...seo}
        url={location.pathname}
        author={post.author.node.name}
      />
      <PostTemplate
        {...post}
        tags={post.tags.nodes}
        featuredImage={getImage(featuredImage.node.imageFile)}
      />
    </ContainerFixed>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostByID($uri: ID!) {
    wp {
      post(
        id: $uri
        idType: URI
      ) {
        ...PostDetailsFields
        seo {
          ...SeoFields
        }
      }
    }
  }
`
