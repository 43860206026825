import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Box, Flex, Heading, Text, Parsed,
} from '../ui'
import TagList from './TagList'
import PostContent from './PostContent'
import parsePostContent from './parsePostContent'

const PostTemplate = ({
  content,
  tags = [],
  title,
  meta,
  author,
  featuredImage,
}) => (
  <Box
    py={2}
    as="article"
  >
    <Parsed
      as="h1"
      fontSize={[5, 7]}
      lineHeight={1.05}
      component={Heading}
    >
      {title}
    </Parsed>

    {/* TODO */}
    <Flex
      py={2}
      mx={-2}
      fontSize={2}
      variant="text.muted"
      flexDirection="row"
      fontWeight="semibold"
    >
      <Text px={2}>
        {author.node.name}
      </Text>
      <Text>·</Text>
      <Text px={2}>
        {meta}
      </Text>
    </Flex>

    <Box
      mt={2}
      mb={4}
      mx={[-3, -3, -3, 0]}
    >
      <GatsbyImage
        alt={title}
        loading="eager"
        image={featuredImage}
      />
    </Box>

    <PostContent>
      {parsePostContent(content)}
    </PostContent>

    <TagList
      pt={3}
      tags={tags}
    />
  </Box>
)

export default PostTemplate
