import styled from '@emotion/styled'
import { Box } from '../ui'

const StyledBox = styled(Box)`
  word-break: break-word;

  h1, h2, h3, h4, h5, h6, p, blockquote, ul, ol, > div:not(.post-gallery), > iframe {
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
  }

  div.wp-video,
  video,
  .wpb_video_wrapper,
  .wpb_video_wrapper iframe {
    width: 100%;
    max-width: 100%;
  }

  video {
    height: auto;
  }

  h1, h2, h3 {
    margin-top: ${({ theme }) => theme.space[4]}px;
    margin-bottom: ${({ theme }) => theme.space[3]}px;
  }

  h4, h5, h6 {
    margin-top: ${({ theme }) => theme.space[3]}px;
    margin-bottom: ${({ theme }) => theme.space[2]}px;
  }

  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }

  h4, h5, h6 {
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }

  a, img {
    border: 0 !important;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  img.size-full {
    width: 100%;
  }

  img.aligncenter {
    width: auto;
    display: block;
    margin: 0 auto;
  }

  p {
    line-height: 1.58;
  }

  blockquote {
    border-left: 3px solid currentColor;
    margin-top: ${({ theme }) => theme.space[5]}px;
    margin-bottom: ${({ theme }) => theme.space[4]}px;
    color: ${({ theme }) => theme.colors.muted};
    padding-left: ${({ theme }) => theme.space[3]}px;
  }

  dd, .gallery-caption {
    margin-left: 0;
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding-top: 3px;
    padding-bottom: 6px;
    opacity: .8;
    font-size: ${(props) => props.theme.fontSizes[1]}px;
  }
`

export default StyledBox
